<template>
  <div id="campPerfectAudit">
    <el-dialog
      title="审核"
      width="1400px"
      :visible.sync="campDialogVisible"
      :close-on-click-modal="false"
      @close="campDialogClose"
    >
      <el-form
        ref="campForm1Ref"
        :model="campForm1"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-row>
              <el-col :span="24">
                <el-form-item label="营地名称" prop="name">
                  <span>{{ campForm1.name }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地类型" prop="type">
                  <span v-if="campForm1.type === 1">公共营地</span>
                  <span v-if="campForm1.type === 2">收费营地</span>
                  <span v-if="campForm1.type === 3">野营地</span>
                  <span v-if="campForm1.type === 4">酒店营地</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地地址" prop="adress">
                  <span>{{ campForm1.adress }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地介绍" prop="introduce">
                  <span>{{ campForm1.introduce }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="联系方式" prop="tel">
                  <span>{{ campForm1.tel }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="场地类型">
                  <el-checkbox-group v-model="checkList1">
                    <el-checkbox v-for="item in cdlxList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地环境">
                  <el-checkbox-group v-model="checkList1">
                    <el-checkbox v-for="item in ydhjList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="可到车辆">
                  <el-checkbox-group v-model="checkList1">
                    <el-checkbox v-for="item in kdclList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="配套设施">
                  <el-checkbox-group v-model="checkList1">
                    <el-checkbox v-for="item in ptssList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地特色">
                  <el-checkbox-group v-model="checkList1">
                    <el-checkbox v-for="item in ydtsList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地照片">
                  <el-image
                    v-for="item in fileList1"
                    :key="item"
                    style="width: 100px; height: 100px"
                    :src="item"
                    :preview-src-list="[item]"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="24">
                <el-form-item label="营地名称" prop="name">
                  <span>{{ campForm2.name }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地类型" prop="type">
                  <span v-if="campForm2.type === 1">公共营地</span>
                  <span v-if="campForm2.type === 2">收费营地</span>
                  <span v-if="campForm2.type === 3">野营地</span>
                  <span v-if="campForm2.type === 4">酒店营地</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地地址" prop="adress">
                  <span>{{ campForm2.adress }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地介绍" prop="introduce">
                  <span>{{ campForm2.introduce }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="联系方式" prop="tel">
                  <span>{{ campForm2.tel }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="场地类型">
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox v-for="item in cdlxList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地环境">
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox v-for="item in ydhjList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="可到车辆">
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox v-for="item in kdclList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="配套设施">
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox v-for="item in ptssList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地特色">
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox v-for="item in ydtsList" :key="item.id" :label="item.id">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营地照片">
                  <el-image
                    v-for="item in fileList2"
                    :key="item"
                    style="width: 100px; height: 100px"
                    :src="item"
                    :preview-src-list="[item]"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="审核结果" prop="status">
              <el-radio-group v-model="reviewFrom.status">
                <el-radio :label="0">
                  不通过
                </el-radio>
                <el-radio :label="1">
                  通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="原因" prop="reason">
              <el-input
                v-model="reviewFrom.reason"
                placeholder="请输入原因"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="campDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="campFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="审核状态">
        <el-select v-model="searchForm.perfectStatus" placeholder="请选择审核状态" clearable>
          <el-option label="未审核" :value="0" />
          <el-option label="审核通过" :value="1" />
          <el-option label="审核未通过" :value="-1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="campPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="pid" label="营地ID" width="80" />
      <el-table-column label="修改日期" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.updateTime">{{ scope.row.updateTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="营地名称" show-overflow-tooltip />
      <el-table-column label="营地类型" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">公共营地</span>
          <span v-if="scope.row.type === 2">收费营地</span>
          <span v-if="scope.row.type === 3">野营地</span>
          <span v-if="scope.row.type === 4">酒店营地</span>
        </template>
      </el-table-column>
      <el-table-column prop="adress" label="营地地址" show-overflow-tooltip />
      <el-table-column label="审核状态" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.perfectStatus === -1">审核未通过</span>
          <span v-if="scope.row.perfectStatus === 0">未审核</span>
          <span v-if="scope.row.perfectStatus === 1">审核通过</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.perfectStatus === 0"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="campPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { perfectList, perfectInfo, perfectAudit } from '@/api/main/camp'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      campDialogVisible: false,
      campForm1: {
        id: '',
        name: '',
        type: '',
        adress: '',
        introduce: '',
        tel: ''
      },
      campForm2: {
        id: '',
        name: '',
        type: '',
        adress: '',
        introduce: '',
        tel: ''
      },
      checkList1: [],
      checkList2: [],
      reviewFrom: {
        id: '',
        status: '',
        reason: ''
      },
      campPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        perfectStatus: ''
      },
      cdlxList: [],
      ydhjList: [],
      kdclList: [],
      ptssList: [],
      ydtsList: [],
      fileList1: [],
      fileList2: [],
      baseUrl: 'https://oss-quye.huahuihongxin.com/'
    }
  },
  created () {
    perfectList(this.searchForm).then(res => {
      this.campPage = res
    })
    selectDictList(2).then((res) => {
      this.cdlxList = res
    })
    selectDictList(3).then((res) => {
      this.ydhjList = res
    })
    selectDictList(4).then((res) => {
      this.kdclList = res
    })
    selectDictList(5).then((res) => {
      this.ptssList = res
    })
    selectDictList(6).then((res) => {
      this.ydtsList = res
    })
  },
  methods: {
    handleSearch () {
      this.searchForm.pageNum = 1
      perfectList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      perfectList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      perfectList(this.searchForm).then((res) => {
        this.campPage = res
      })
    },
    campDialogClose () {
      this.campForm1 = {
        id: '',
        name: '',
        type: '',
        adress: '',
        introduce: '',
        tel: ''
      },
      this.campForm2 = {
        id: '',
        name: '',
        type: '',
        adress: '',
        introduce: '',
        tel: ''
      },
      this.checkList1 = [],
      this.checkList2 = [],
      this.reviewFrom = {
        id: '',
        status: '',
        reason: ''
      }
    },
    campFormSubmit () {
      if (this.reviewFrom.status === '') {
        this.$message({
          message: '请选择审核结果',
          type: 'warning',
          center: true
        })
        return
      }
      perfectAudit(this.reviewFrom).then(res => {
        this.searchForm.pageNum = 1
        perfectList(this.searchForm).then((res) => {
          this.campPage = res
        })
        this.campDialogVisible = false
      })
    },
    handleReview (index, row) {
      this.campDialogVisible = true
      this.reviewFrom.id = row.id
      perfectInfo(row.id).then(res => {
        this.campForm1.id = res.camp.id
        this.campForm1.name = res.camp.name
        this.campForm1.type = res.camp.type
        this.campForm1.adress = res.camp.adress
        this.campForm1.introduce = res.camp.introduce
        this.campForm1.tel = res.camp.tel
        if (res.camp.dict) {
          const dict = res.camp.dict.split(',')
          this.checkList1 = []
          for (let i = 0; i < dict.length; i++) {
            this.checkList1.push(parseInt(dict[i]))
          }
        }
        if (res.camp.pic) {
          const picUrl = res.camp.pic.split(',')
          this.fileList1 = []
          for (let i = 0; i < picUrl.length; i++) {
            this.fileList1.push(this.baseUrl + picUrl[i])
          }
        }

        this.campForm2.id = res.campTemp.id
        this.campForm2.name = res.campTemp.name
        this.campForm2.type = res.campTemp.type
        this.campForm2.adress = res.campTemp.adress
        this.campForm2.introduce = res.campTemp.introduce
        this.campForm2.tel = res.campTemp.tel
        if (res.campTemp.dict) {
          const dict = res.campTemp.dict.split(',')
          this.checkList2 = []
          for (let i = 0; i < dict.length; i++) {
            this.checkList2.push(parseInt(dict[i]))
          }
        }
        if (res.campTemp.pic) {
          const picUrl = res.campTemp.pic.split(',')
          this.fileList2 = []
          for (let i = 0; i < picUrl.length; i++) {
            this.fileList2.push(this.baseUrl + picUrl[i])
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
